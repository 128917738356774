import Vue from 'vue';
import Keycloak from 'keycloak-js';

const initOptions = {
    url: process.env.VUE_APP_KEYCLOAK_ENDPOINT,
    realm: process.env.VUE_APP_KEYCLOAK_REALM,
    clientId: process.env.VUE_APP_KEYCLOAK_CLIENT_ID,
    checkLoginIframe: false
}

const _keycloak = new Keycloak(initOptions)

const Plugin = {
    install(Vue) {
      Vue.$keycloak = _keycloak
    }
}

Plugin.install = Vue => {
    Vue.$keycloak = _keycloak
    Object.defineProperties(Vue.prototype, {
      $keycloak: {
        get() {
          return _keycloak
        }
      }
    })
  }

Vue.use(Plugin);

export default Plugin;
