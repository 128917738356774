class FeatureFlag {
  featureFlags = undefined;

  constructor(keycloak) {
    this.keycloak = keycloak
    this.host = process.env.VUE_APP_FF_HOST
    this.instanceId = process.env.VUE_APP_FF_INSTANCEID

    this.headers = {
      'UNLEASH-INSTANCEID': this.instanceId,
      'UNLEASH-APPNAME': process.env.VUE_APP_ENV
    }
  }

  async fetchAll() {
    try {
      const headers = this.headers
      const res = await fetch(`${this.host}/features`, {headers});
      const data = await res.json();
      this.featureFlags = data.features;
    } catch (e) {
      console.error('There was an error:', e)
    }
  }

  async isEnabled(featureName) {
    if (this.featureFlags === undefined) {
      await this.fetchAll()
    }

    if (this.featureFlags.length === 0) {
      return false;
    }

    const featureFlag = this.featureFlags.find(featureFlag => featureFlag.name === featureName)

    if (featureFlag === undefined) {
      return false;
    }

    if (!featureFlag.enabled) {
      return false;
    }

    const results = [];

    for (const strategy of featureFlag.strategies) {
      if (strategy.name === "userWithId") {
        const userIds = strategy.parameters.userIds.trim().split(',');
        const profile = await this.keycloak.loadUserProfile();
        results.push(userIds.includes(profile.email));
      } else {
        results.push(true);
      }
    }

    return results.includes(true);
  }

  async isDisabled(featureName) {
    return !await this.isEnabled(featureName)
  }
}


export default {
    // eslint-disable-next-line no-unused-vars
    install(Vue, options) {
      Vue.prototype.$featureFlag = new FeatureFlag(Vue.$keycloak)
    }
}

