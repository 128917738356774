import WebFontLoader from 'webfontloader'

// async load fonts
WebFontLoader.load({
  google: {
    families: [
      'Space+Grotesk:wght@300;400;500;600;700&display=swap',
      'Anonymous+Pro:ital,wght@0,400;0,700;1,400;1,700&display=swap'
    ],
  },
})
