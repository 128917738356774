import {defineStore} from "pinia";
import {GET_USER_QUERY} from '@/graphql/queries/UserQueries';
import Vue from 'vue';

export const useUserStore = defineStore('user/user', {
    state: () => ({
        account: {},
        subscriptions: [],
        permissions: {}
    }),
    actions: {
      async getUser() {
        try {
          const response = await Vue.prototype.$query(GET_USER_QUERY);
          this.account = response.data.account;
          this.subscriptions = this.account.customer.subscriptions;
        } catch (error) {
          // Error. In this case, bring the application to the login page
          Vue.$keycloak.logout();
          throw error; // Re-throw the error to be caught in the router
        }
      },
      async setUserPermissions() {
        try {
          if (await Vue.prototype.$featureFlag.isDisabled('billing')) {
            return;
          }
          const permissions = await Vue.prototype.$getAuthorize();

          if (permissions.error !== undefined) {
            this.permissions = {};
            return;
          }

          permissions.forEach((permission) => {
            this.permissions[permission.rsname] = permission.scopes || null;
          });
        } catch (error) {
          this.permissions = {};
          throw error;
        }
      },
      async isGranted(action, resource) {
        if (await Vue.prototype.$featureFlag.isDisabled('billing')) {
          return true;
        }
        await this.setUserPermissions();

        if (this.permissions[resource] === undefined) {
          return false;
        }

        return this.permissions[resource]?.includes(action) || false;
      }
    }
});
