export default {
  install(Vue) {
    Vue.prototype.$getAuthorize = function() {
      const url = `${process.env.VUE_APP_KEYCLOAK_ENDPOINT}/realms/${process.env.VUE_APP_KEYCLOAK_REALM}/protocol/openid-connect/token`;
      const KEYCLOAK_TOKEN = this.$keycloak.token; // replace with your actual token

      if (!KEYCLOAK_TOKEN) {
        throw new Error("No token found");
      }

      const headers = {
        "Content-Type": "application/x-www-form-urlencoded",
        "Authorization": `Bearer ${KEYCLOAK_TOKEN}`
      };
      const body = new URLSearchParams({
        grant_type: "urn:ietf:params:oauth:grant-type:uma-ticket",
        audience: process.env.VUE_APP_KEYCLOAK_CLOUD_PORTAL_BACKEND_CLIENT_ID,
        response_mode: "permissions",
      });
      return fetch(url, {
        method: "POST",
        headers: headers,
        body: body.toString()
      })
        .then(response => response.json())
        .catch((error) => console.log("Error:", error));
    }
  }
}
