import { gql } from '@apollo/client/core';

export const GET_USER_QUERY = gql`
  query getUser {
    account {
      id
      role
      email
      name
      customer {
        id
        tag
        subscriptions {
          type
          status
          reason
        }
        billingPeriod
        termOfPayment
        recurringPayment
        address {
          streetNameAndHouseNumber
          postalCode
          city
          country
        }
      }
    }
  }
`

export const GET_USER_AUTHORIZATION = gql`
  query getUserAuthorization($action: String!) {
    action(action: $action) {
      isPossible
      reason
      date
    }
  }
`
