import { themeConfig, themePreset } from "@/themeConfig";

import { defineStore } from "pinia";

export const useThemeConfigStore = defineStore('theme/themeConfig', {
    state: () => ({
        themeMode: themeConfig,
        vuetifyPreset: themePreset
    }),
    getters: {
        getThemeMode: (state) => state.themeMode,
        getVuetifyPreset: (state) => state.vuetifyPreset
    },

    actions: {
        toggleThemeFetchingState(payload) {
            this.themeMode.isFetching = payload;
        },
        toggleThemeLoadingState(payload) {
            this.themeMode.isLoading = payload;
        },
        toggleVerticalSidebarDrawer() {
            this.themeMode.isVerticalSidebarOpen = !this.themeMode.isVerticalSidebarOpen;
        },
        setNotFoundState(payload) {
            this.themeMode.isNotFound = payload;
        }
        // changeThemeMode({ commit }) {
        //     commit("toggleThemeMode");
        // },
        // changeThemeLayout({ commit }, data) {
        //     commit("toggleThemeLayout", data);
        // },
        // changeThemeDarkMode({ commit }, data) {
        //     commit("toggleThemeDarkMode", data);
        // },
        // changeThemeRtl({ commit }) {
        //     commit("toggleThemeRtl");
        // },

        // changeVerticalSaasSidebarDrawer({ commit }) {
        //     commit("toggleVerticalSaasSidebarDrawer");
        // },
        // changeVerticalSidebarDrawerColor({ commit }, data) {
        //     commit("toggleVerticalSidebarDrawerColor", data);
        // },
        // changeAppBarColor({ commit }, data) {
        //     commit("toggleAppBarColor", data);
        // },
        // changeCompactVerticalSidebarDrawer({ commit }) {
        //     commit("toggleCompactVerticalSidebarDrawer");
        // },
        // changeVerticalSidebarMini({ commit }) {
        //     commit("toggleVerticalSidebarMini");
        // },
        // toggleThemeDarkMode(state, data) {
        //     state.themeMode.isDark = data;
        //     this.$vuetify.theme.dark = data;
        // },
        // toggleThemeLayout(state, data) {
        //     state.themeMode.layout = data;
        // },
        // toggleThemeRtl(state) {
        //     state.themeMode.rtl = !state.themeMode.rtl;
        // },
        // toggleVerticalSidebarDrawer(state) {
        //     state.themeMode.verticalSidebarDrawer = !state.themeMode
        //         .verticalSidebarDrawer;
        // },
        // toggleVerticalSaasSidebarDrawer(state) {
        //     state.themeMode.verticalSaasSidebarDrawer = !state.themeMode
        //         .verticalSaasSidebarDrawer;
        // },
        // toggleVerticalSidebarDrawerColor(state, data) {
        //     state.themeMode.verticalSidebarDrawerColor = data;
        // },
        // toggleAppBarColor(state, data) {
        //     state.themeMode.appBarColor = data;
        // },
        // toggleCompactVerticalSidebarDrawer(state) {
        //     state.themeMode.verticalCompactSidebarDrawer = !state.themeMode
        //         .verticalCompactSidebarDrawer;
        // },
        // toggleVerticalSidebarMini(state) {
        //     state.themeMode.verticalSidebarMini = !state.themeMode.verticalSidebarMini;
        // }
    }


});
