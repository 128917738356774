// Locale
// import { en } from '../../locale'
import colors from "vuetify/lib/util/colors";
export const themeConfig = {
  disableCustomizer: true, // options[Boolean] : true, false(default)
  isVerticalSidebarOpen: true, // options[Boolean] : true, false(default)
  verticalCompactSidebarDrawer: true, // options[Boolean] : true, false(default)
  verticalSaasSidebarDrawer: true, // options[Boolean] : true, false(default)
  showBreadcrumb: true, // options[Boolean] : true, false(default)
  isNotFound: false, // options[Boolean] : true, false(default),
  layout: "VerticalSix", //VerticalCompactSidebar,VerticalSaasLayout,VerticalSidebar,HorizontalBar,VerticalSix
  isLoading: false,
  isFetching: false,
  isDark: false,
  verticalSidebarDrawerColor: "white", // primary,white,success,warning,etc
  appBarColor: "header", // primary,white,success,warning,etc
};

// const mq = window.matchMedia("(prefers-color-scheme: light)");

// export const vuetify = new Vuetify({
//   theme: { dark: mq.matches }
// });

// console.log(mq);

// mq.addEventListener("change", e => {
//   console.log(mq);
//   this.$vuetify.theme.dark = e.matches;
// });

export const themePreset = {
  breakpoint: {
    scrollBarWidth: 16,
    thresholds: {
      xs: 600,
      sm: 960,
      md: 1280,
      lg: 1920,
    },
  },

  rtl: false,

  theme: {
    // dark: mq.matches,
    dark: false,
    default: "light",
    disable: false,
    options: {
      cspNonce: undefined,
      customProperties: true,
      minifyTheme: undefined,
      themeCache: undefined,
    },
    themes: {
      light: {
        primary: '#1e5a64',
        secondary: '#E4F4F5',
        inputfield: "#EFF8F9",
        action: "#FF4F51",
        accent: "#016FD0",
        success: colors.green,
        danger: colors.red,
        error: colors.red,
        warning: colors.deepOrange,
        info: "#94A4B4",
        dark: "#242939",
        background: "#f2f3f8",
        black: colors.black,
        header: "#91D2D7",
        title: "#304156",
      },
      dark: {
        primary: colors.blue,
        secondary: "#304156",
        success: colors.green,
        danger: colors.red,
        warning: colors.deepOrange,
        info: colors.indigo,
      },
    },
  },
};
