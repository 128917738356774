import VuePerfectScrollbar from "vue-perfect-scrollbar";

// theme Main scss
import "@/assets/scss/octavia-design-system/octavia-design-system.scss";

// plugins menu

// javascript import for when you're importing the css directly in your javascript
import "vue-navigation-bar/dist/vue-navigation-bar.css";
// import the library
import VueNavigationBar from "vue-navigation-bar";

// form-Wizard
import VueFormWizard from "vue-form-wizard";
import "vue-form-wizard/dist/vue-form-wizard.min.css";

import VueMeta from "vue-meta";

// you can set default global options and events when use

// end plugins

export default {
  install(Vue) {
    Vue.use(VueMeta);
    Vue.use(VueFormWizard);

    Vue.component("VueNavigationBar", VueNavigationBar);
    Vue.component(
      "VerticalSix",
      // The `import` function returns a Promise.

      () => import("../layouts/vertical/Index")
    );

    Vue.component("VuePerfectScrollbar", VuePerfectScrollbar);
  },
};
