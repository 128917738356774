import Vue from 'vue';

const initOptions = {
  site: 'tilaacloud-test',
  publishableKey: 'test__'
}

const _chargebee = window.Chargebee ? window.Chargebee.init(initOptions) : null;

const Plugin = {
  install(Vue) {
    Vue.$chargebee = _chargebee
  }
}

Plugin.install = Vue => {
  Vue.$chargebee = _chargebee
  Object.defineProperties(Vue.prototype, {
    $chargebee: {
      get() {
        return _chargebee
      }
    }
  })
}

Vue.use(Plugin);

export default Plugin;
