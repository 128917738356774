import apolloClient from "@/plugins/apollo";

const query = async (query, variables = null) => {
  return await apolloClient.query(
    {
      query: query,
      fetchPolicy: "no-cache",
      variables: variables,
    }
  )
};

const mutation = async (mutation, variables = null, queryToFetch = null) => {
  return await apolloClient.mutate(
    {
      mutation: mutation,
      fetchPolicy: "no-cache",
      variables: variables,
      refetchQueries: queryToFetch
    }
  )
};

export default {
  install(Vue) {
    Vue.prototype.$query = query
    Vue.prototype.$mutation = mutation
  }
}
