// Vuetify Documentation https://vuetifyjs.com

import Vue from "vue";
import Vuetify from "vuetify/lib";
import { themePreset } from "@/themeConfig";
import RocketIcon from "@/assets/svg-icon-components/RocketIcon.vue";
import LogIcon from "@/assets/svg-icon-components/LogIcon.vue";

Vue.use(Vuetify);

export default new Vuetify({
  theme: themePreset.theme,
  rtl: themePreset.rtl,
  icons: {
    values: {
      rocket: { // name of our custom icon
        component: RocketIcon, // our custom component
      },
      log: { // name of our custom icon
        component: LogIcon, // our custom component
      }
    },
  },
});
