<template>
  <router-view />
</template>

<script>

export default {
  name: "App",
  metaInfo() {
    return {
      // if no subcomponents specify a metaInfo.title, this title will be used.
      title: "My Tilaa",
      // all titles will be injected into this template
      titleTemplate: "%s | Cloud Portal",
    };
  },
};
</script>
